import {Cancel, DeleteOutlined, Save} from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {callApi, deleteApi, saveApi} from "../App";
import {isCIDRValid} from "./Ec2SecurityGroupEditor";

const PORT = "port";
const CIDR = "cidr";
const DESC = "desc";
export default function Ec2SecurityGroupEditorV2(props) {
    const { enqueueSnackbar } = useSnackbar();
    const instanceId = props.securityGroup.instance.id
    const profile = props.securityGroup.instance.profile
    const sgId = props.securityGroup.id
    const gitlab = props.securityGroup.instance.gitlab
    const [sgRule, setSgRule] = useState({})
    const [permissions, setPermissions] = useState([])

    useEffect(() => {
        fetchSecurityGroup()
    },[props.securityGroup.id])

    return (
        <Dialog open={true} onClose={() => props.setSecurityGroup({})}>
            <DialogTitle>
            { props.securityGroup.readOnly === undefined ? "보안 그룹 변경" : "보안 그룹 조회"}
            </DialogTitle>

            <DialogContent>
                <TextField
                    id="port"
                    label="Port"
                    value={sgRule[PORT]}
                    placeholder={"포트(80)"}
                    variant="standard"
                    InputProps={{
                        readOnly: props.securityGroup.readOnly !== undefined
                    }}
                    sx ={{width:"100px"}}
                    onChange={(e) => handleInputChange(PORT, e)}
                />
                <TextField
                    id="IP"
                    label="IP"
                    value={sgRule[CIDR]}
                    placeholder={"123.123.123.123/32"}
                    variant="standard"
                    InputProps={{
                        readOnly: props.securityGroup.readOnly !== undefined
                    }}
                    onChange={(e) => handleInputChange(CIDR, e)}
                />
                <TextField
                    id="description"
                    label="설명"
                    value={sgRule[DESC]}
                    placeholder={"규칙에 대한 설명..."}
                    variant="standard"
                    InputProps={{
                        readOnly: props.securityGroup.readOnly !== undefined
                    }}
                    onChange={(e) => handleInputChange(DESC, e)}
                />

                <Table>
                    <TableBody>
                        {permissions.map(rule => {
                            const port = rule.fromPort === 0 || rule.fromPort === null ? "All Port(0)" : rule.fromPort
                            const firstList = rule.ranges.map(ip =>
                                <TableRow>
                                    <TableCell>{port}</TableCell>
                                    <TableCell>{ip.cidr}</TableCell>
                                    <TableCell>{ip.desc}</TableCell>
                                    <TableCell>
                                    { props.securityGroup.readOnly === undefined ? 
                                        <Tooltip title="보안 그룹 규칙을 삭제합니다." placement="top">
                                            <DeleteOutlined onClick={() => deleteRule(rule, ip)}/>
                                        </Tooltip> : <></> }
                                    </TableCell>
                                </TableRow>)
                            const secondList = rule.userIdGroupPairs.map(group => <TableRow>
                                    <TableCell>
                                        {rule.fromPort==null?'All Port': rule.fromPort}
                                        {rule.toPort==null || rule.toPort === rule.fromPort ? '' : '-'+rule.toPort}</TableCell>
                                    <TableCell>{group.groupId}</TableCell>
                                    <TableCell>{group.description}</TableCell>
                                    <TableCell>
                                    { props.securityGroup.readOnly === undefined ? 
                                        <Tooltip title="보안 그룹 규칙을 삭제합니다." placement="top">
                                            <DeleteOutlined onClick={() => deleteRule(rule, group.groupId)}/>
                                        </Tooltip> : <></> }
                                    </TableCell>
                                </TableRow>)
                            return firstList.concat(secondList)
                        })}
                        {props.securityGroup.readOnly && permissions.length === 0 ? 
                        <TableRow><TableCell>등록된 규칙이 없습니다.</TableCell></TableRow> : ""}
                    </TableBody>
                </Table>
            </DialogContent>

            <DialogActions>
                { props.securityGroup.readOnly === undefined &&
                <Tooltip title="입력한 보안 IP정보를 저장합니다." placement="top">
                    <Button variant="contained" startIcon={<Save />} color="primary" onClick={addNewRule}>
                        저장
                    </Button>
                </Tooltip>}
                <Tooltip title="팝업 창을 닫습니다." placement="top">
                    <Button variant="outlined" startIcon={<Cancel />} color="secondary" onClick={() => props.setSecurityGroup({})}>
                        닫기
                    </Button>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );

    function fetchSecurityGroup() {
        console.debug("[Ec2SecurityGroupEditorV2.fetchSecurityGroup] gitlab : ", gitlab)
        console.debug("[Ec2SecurityGroupEditorV2.fetchSecurityGroup] security group id : ", sgId)
        const instance = props.securityGroup.instance
        const uri = `/ec2/${instance.id}/securityGroup/${sgId}/fetch?profile=${instance.profile}&gitlab=${gitlab}`
        callApi(uri, (res) => {
            console.debug("[Ec2SecurityGroupEditorV2.fetchSecurityGroup] security group info : ", res)
            setPermissions(res.permissions);
        }, null, (msg) => enqueueSnackbar(msg.msg, {variant: msg.severity}))
    }


    function handleInputChange(key, e) {
        sgRule[key] = e.target.value
        setSgRule({...sgRule})
    }

    function addNewRule() {
        let hasError = false;
        if (sgRule[PORT] === undefined || sgRule[PORT].toString().trim().length === 0 || isNaN(sgRule[PORT] || sgRule[PORT] === '0')) {
            enqueueSnackbar('사용하려는 포트 값을 입력 하세요.', {variant: "error"})
            hasError = true
        }
        if(sgRule[CIDR] === undefined || sgRule[CIDR].toString().trim().length === 0 ){
            enqueueSnackbar('접속하는 곳의 Public IP를 입력 하세요', {variant: "error"})
            hasError = true
        }
        if (!isCIDRValid(sgRule[CIDR])) {
            enqueueSnackbar('올바른 IP형식이 아닙니다. "1.1.1.1/32" 형식으로 입력 하세요', {variant: "error"})
            hasError = true
        }
        if( hasError ) return ;

        if( sgRule[PORT] === '0' && sgRule[CIDR].includes("0.0.0.0")){
            enqueueSnackbar('Port와 IP모두 개방할 수 없습니다. 포트 또는 IP를 지정하세요 !!',{variant: 'error'})
            return
        }
        console.debug(`sg add : ${sgRule}`)
        //sgRule["port"] = parseInt(sgRule["port"])
        const uri = `/ec2/${instanceId}/securityGroup/${sgId}/add?profile=${profile}&gitlab=${gitlab}`
        saveApi(uri, 'POST', sgRule, (json) => {
            console.debug(`response json : ${json}`)
            enqueueSnackbar('방화벽에 IP와 PORT 규칙을 추가했습니다.',{variant: 'success', autoHideDuration: 3000})
            fetchSecurityGroup()
        }, (msg) => {enqueueSnackbar(msg.msg, { variant: "error" })})

    }

    function deleteRule(rule, ip) {
        if( window.confirm(`${rule.fromPort===null || rule.fromPort === 0 ? "모든포트" : rule.fromPort} 사용 ${ip.cidr}(${ip.desc}) 규칙을 삭제하시겠습니까?`))
        console.debug(`${rule}, ${ip}`);
        const sgRule = {port : rule.fromPort, cidr: ip.cidr, desc: ip.desc}

        const uri = `/ec2/${instanceId}/securityGroup/${sgId}/delete?profile=${profile}&gitlab=${gitlab}`
        deleteApi(uri, sgRule, () => {
            enqueueSnackbar('방화벽에서 IP와 PORT 규칙을 삭제했습니다.',{variant: 'success', autoHideDuration: 5000})
            fetchSecurityGroup()
        }, "보안그룹", (msg) => {enqueueSnackbar(msg.msg, {variant: msg.severity})})
    }
}
