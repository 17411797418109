import React, {useContext, useState} from "react";
import {MainContext} from "../Main";
import ResourceToolbar from "./ResourceToolbar";


export default function ElasticIPs() {
    const context = useContext(MainContext)
    const [resourceName,setResourceName] = useState("")

    return (<>
        <ResourceToolbar reload={reload} resourceName={resourceName} searchResource={setResourceName} />
        <div className="row px-3"></div>

    </>)

    function reload() {

    }
}