import {TextField} from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useSnackbar} from 'notistack';
import React, {useContext, useState} from 'react';
import {saveApi, UserContext} from "../App";

const IssueDialog = ({setOpen}) => {
    const user = useContext(UserContext)
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const handleSave = () => {
        // Handle the form submission logic here
        console.log('Title:', title);
        console.log('Content:', content);

        const description = content +`\n\n작성자 : @${user.name}`
        // save the content
        saveApi("/issue/add", "PUT", {title : title, description: description}, ()=>{
            enqueueSnackbar("저장 되었습니다. ",{variant:"success"})
        }, (msg) => {enqueueSnackbar(msg.msg, { variant: "error" })}) 
    
    };

    return (
            <Dialog open={true} onClose={() => setOpen(false)}>
                <DialogTitle>이슈/의견 등록</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="Title"
                        type="text"
                        fullWidth
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="content"
                        label="Content"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>window.open("https://git.bwg.co.kr/gitlab/feedback/system/-/issues/new")} color="info">
                        Open Gitlab Issues
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
    );


};

export default IssueDialog;
