import {
    Cancel,
    ContentCopy,
    DoNotDisturbOnOutlined,
    FavoriteOutlined,
    PlayCircleFilledOutlined,
    ReplayOutlined,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    Box,
    Collapse, FormControl, InputLabel,
    Link,
    Menu,
    MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {useSnackbar} from "notistack";
import React, {useContext, useEffect, useState} from "react";
import {callApi, UserContext} from "../App";
import {MainContext} from "../Main";
import Ec2SecurityGroupEditor from "./Ec2SecurityGroupEditor";
import Ec2TagEditDialog from "./Ec2TagEditDialog";
import Ec2Toolbar, {openPage} from "./Ec2Toolbar";
import SecurityGroupListDialog from "./SecurityGroupListDialog";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {arvhiveOrRestoreIcon} from "./Ec2Admin";

export default function Ec2() {
    const context = useContext(MainContext)
    const { enqueueSnackbar } = useSnackbar();
    const [rows, setRows] = useState([]);
    const [instance, setInstance] = useState(null);
    const [serverName, setServerName] = useState("")
    const [securityGroup, setSecurityGroup] = useState({})
    const [serverState, setServerState] = useState("")

    useEffect(() => {
        if (context.servers) setRows(context.servers)
    }, [context.servers])

    // rows.map((row,index) => console.log(`${index} : `+row.tags["group"]))

    return (<>
        <Ec2Toolbar filter={context.keyword} setFilter={context.setKeyword} setServerName={setServerName} reload={reload} />
        <div className="row py-1"></div>
        <div className="row px-4 py-5">
            <TableContainer component={Paper} stickyHeader>
                {/*<Table sx={{ minWidth: 650 }} aria-label="server table">*/}
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Group</TableCell>
                        <TableCell>Wiki</TableCell>
                        <TableCell>
                            <FormControl variant="outlined" size="small" style={{ marginLeft: 10, minWidth: 120 }}>
                                <InputLabel>서버</InputLabel>
                                <Select small label="서버" onChange={(e) => setServerState(e.target.value)}>
                                    <MenuItem value={""}>All</MenuItem>
                                    <MenuItem value={"running"}>Running</MenuItem>
                                    <MenuItem value={"stopped"}>Stopped</MenuItem>
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell>IP</TableCell>
                        <TableCell>시작/정지 일정</TableCell>
                        <TableCell>시작 시간</TableCell>
                        <TableCell>Endpoint</TableCell>
                        <TableCell>Disk</TableCell>
                        <TableCell>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.filter((row) => context.keyword === "" || groupCheck(row.group, context.keyword))
                        .filter((row) => checkFilter(row, serverName, serverState))
                        .map((row, index) =>
                            <Row idx={index + 1} key={row.id} row={row} editTag={editTag}
                                editSecurityGroup={editSecurityGroup} editSecurityGroups={editSecurityGroups}
                                setAlertMsg={setAlertMsg}
                            />
                        )}
                </TableBody>
                {/*</Table>*/}
            </TableContainer>
        </div>
        {instance !== null && securityGroup.id === undefined &&
            <Ec2TagEditDialog instance={instance} setInstance={setInstance} />}
        {securityGroup.securityGroup !== undefined && securityGroup.manageSecurityGroup === false &&
            <Ec2SecurityGroupEditor securityGroup={securityGroup} setSecurityGroup={setSecurityGroup} />}
        {securityGroup.manageSecurityGroup &&
            <SecurityGroupListDialog securityGroup={securityGroup} setSecurityGroup={setSecurityGroup} setInstance={setInstance} />}
    </>
    );

    function setAlertMsg(msg) {
        enqueueSnackbar(msg.msg, { variant: msg.severity });
    }

    function editSecurityGroups(instance) {
        setSecurityGroup({ instance: instance, manageSecurityGroup: true })
    }


    function editTag(instance) {
        console.debug("editTag:", instance)
        setInstance(instance)
    }

    function reload() {
        context.setServers([])
    }

    function editSecurityGroup(instance, sgId) {
        console.debug("security group id : ", sgId)
        const gitlab = instance.tags["gitlab"] ? instance.tags["gitlab"] : ""
        const uri = `/ec2/${instance.id}/securityGroup/${sgId}/fetch?profile=${instance.profile}&gitlab=${gitlab}`
        callApi(uri, (res) => {
            console.debug("security group info : ", res)
            setSecurityGroup({ securityGroup: res, instance: instance, manageSecurityGroup: false });
        }, null, (msg) => { setAlertMsg(msg) })
    }
}

export function fetchServerInfo(instance, setAlertMsg, context) {
    instance.state = "fetch"
    //setAlertMsg({ severity: "info", msg: "조회 중...." , autoHideDuration: 2000})
    const gitlab = instance.tags["gitlab"] ? instance.tags["gitlab"] : ""
    callApi(`/ec2/instance/${instance.id}/fetch?profile=${instance.profile}&gitlab=${gitlab}`, (res) => {
        if (setAlertMsg) setAlertMsg({ severity: "success", msg: "조회 되었습니다." })
        if (res.id === undefined) {
            alert(res);
            return;
        }

        console.debug(`fetched server info : ${res}`)
        const serverIndex = context.servers.findIndex((server) => server.id === res.id);
        if (serverIndex !== -1) {
            context.servers[serverIndex] = res;
            context.setServers([...context.servers])
        } else {
            if (setAlertMsg) setAlertMsg({ severity: "warning", msg: "서버 목록에서 해당 서버를 찾을 수 없습니다. 전체 목록 재조회를 하세요!" })
        }
    }, null, setAlertMsg)
}

export function checkFilter(row, keyWords, serverState) {

    const result = (keyWords === ""
        || row.name.includes(keyWords)
        || row.group.includes(keyWords) || row.privateIpAddress.includes(keyWords)
        || (row.publicIpAddress && row.publicIpAddress.includes(keyWords))
        || row.id.includes(keyWords)
        || row.gitlab.includes(keyWords)
        || row.profile.includes(keyWords) || row.type.includes(keyWords)
        || (row.description != null && row.description.includes(keyWords))
        || row.securityGroup.map(sg => sg.name).join().includes(keyWords)
        )
        && (serverState === "" || row.state.includes(serverState))
    //console.debug(`${row.name} checkFilter: ${result}`)
    return result;
}

export function startServer(instance, setAlertMsg) {
    if (window.confirm(`${instance.name} 서버를 시작 하시겠습니까? \n시작 되지 않은 서비스는 개별 시작 해야 합니다.`)) {
        //setLoadingState(true)
        const gitlab = instance.tags["gitlab"] ? instance.tags["gitlab"] : ""
        callApi(`/ec2/instance/${instance.id}/start?profile=${instance.profile}&gitlab=${gitlab}`, (res) => {
            console.debug(`response : ${res.result}`)
            instance.state = "pending"

            setAlertMsg({
                severity: "success", msg: "서버 시작까지 약 1분 정도 소요 됩니다. 1분 후 상태를 갱신 하세요.\n\n" +
                    "서버에서 기동되는 서비스는 시간이 더 필요 합니다. 서버 시작 후 별도로 확인 하세요 "
            })
        }, null, setAlertMsg)
    }
}

export function stopServer(instance, setAlertMsg) {
    if (window.confirm(`${instance.name} 서버를 정지 하시겠습니까? \n해당 서버의 모든 서비스가 정지 됩니다.`)) {
        //setLoadingState(true)
        const gitlab = instance.tags["gitlab"] ? instance.tags["gitlab"] : ""
        callApi(`/ec2/instance/${instance.id}/stop?profile=${instance.profile}&gitlab=${gitlab}`, (res) => {
            // StopInstancesResponse(StoppingInstances=[InstanceStateChange(CurrentState=InstanceState(Code=64, Name=stopping)
            console.debug(`response : ${res.result}`)
            instance.state = "stopping"
            //setLoadingState(false)
            setAlertMsg({ severity: "success", msg: "서버 정지까지 약 1분 정도 소요 됩니다. 1분 후 상태를 갱신 하세요." })
        }, null, setAlertMsg)
    }
}

export function powerIcon(instance, setAlertMsg, mainContext) {
    if (instance.state === "running") {
        if (instance.accessLevel > 20)
            return (<Tooltip title="가동중! 클릭하면 서버를 정지합니다." placement="top">
                <Link href={"#"} onClick={() => stopServer(instance, setAlertMsg)}>
                    <PlayCircleFilledOutlined /></Link></Tooltip>)
        else
            return (<Tooltip title="가동중!" placement="top"><PlayCircleFilledOutlined className="text-reagent-gray" /></Tooltip>)
    } else if (instance.state === "stopped") {
        if (instance.accessLevel > 20 && instance.blockDevice.length >= 1)
            return (<Tooltip title="정지됨! 클릭하면 서버를 시작합니다." placement="top"><Link href={"#"} onClick={() => startServer(instance, setAlertMsg)}>
                <DoNotDisturbOnOutlined /></Link></Tooltip>)
        else if (instance.accessLevel > 20 && instance.blockDevice.length === 0)
            return (<Tooltip title="정지됨! Disk의 Volume 복구 후 시작할 수 있습니다. -> 아이콘을 눌러 Volume을 복구하세요." placement="top"><DoNotDisturbOnOutlined className="text-reagent-gray" /></Tooltip>)
        else
            return (<Tooltip title="정지됨!" placement="top"><DoNotDisturbOnOutlined className="text-reagent-gray" /></Tooltip>)
    } else if (instance.state === "terminated") {// pending?
        return (<Tooltip title="종료됨! - 더이상 사용할 수 없습니다." placement="top"><Cancel className="text-reagent-gray" /></Tooltip>)
    } else if (instance.state === "pending") {// pending?
        return (<Tooltip title="시작 하는 중! 클릭하면 상태를 다시 조회 합니다." placement="top"><Link href={"#"} onClick={() => fetchServerInfo(instance, setAlertMsg, mainContext)}>
            <ReplayOutlined /></Link></Tooltip>)
    } else if (instance.state === "stopping") {
        return (<Tooltip title="정지 하는 중! 클릭하면 상태를 다시 조회 합니다." placement="top"><Link href={"#"} onClick={() => fetchServerInfo(instance, setAlertMsg, mainContext)}>
            <ReplayOutlined /></Link></Tooltip>)
    } else if (instance.state === "fetch") {
        return (<Tooltip title="상태 조회 중! 클릭하면 상태를 다시 조회 합니다." placement="top"><Link href={"#"} onClick={() => fetchServerInfo(instance, setAlertMsg, mainContext)}>
            <ReplayOutlined /></Link></Tooltip>)
    } else {
        return (<Tooltip title={`${instance.state}: 클릭 하면 서버 상태를 조회 합니다.`} placement="top"><Link href={"#"} onClick={() => fetchServerInfo(instance, setAlertMsg, mainContext)}>
            <ReplayOutlined /></Link></Tooltip>)
    }

}

export function groupCheck(group, filter) {
    const filterList = filter.split(",")
    const groupList = group.split(",").map(v => v.trim()); // , ㄷㅏ음 space가 있을 수 있다.

    return groupList.filter(g => filterList.includes(g)).length > 0
}

function makeLink(instance) {
    if (instance.endpoint === null || instance.state !== "running") return ""

    // let list = []
    // instance.endpoint.split(";").forEach((u, i) => {
    //     const url = u.replace("{}", instance.publicIpAddress)

    //     if (url.length > 0 && i > 0) list.push(<br></br>)
    //     if (u.startsWith("http")) list.push(<a href={url} target='_blank' rel="noreferrer">{url}</a>);
    //     else if (url.length > 0) list.push(<>{url}</>);
    // })

    // return list;
    const endpoints = instance.endpoint.replaceAll("{}", instance.publicIpAddress).split(";")
    return (
        <PopupState variant="popover" popupId="family-site-popup-menu" disableAutoFocus={false} parentPopupState={null}>
            {(popupState) => (
                <React.Fragment>
                    <Link variant="contained" {...bindTrigger(popupState)}>Endpoint</Link>
                    <Menu {...bindMenu(popupState)}>
                        {endpoints.map((endpoint, index) =>
                            <MenuItem key={index} onClick={() => openPage(popupState, endpoint)}>{endpoint}</MenuItem>)}
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>)
}

export function getEndpointStatusIcon(instance) {
    if (instance.endpointStatus !== null) {
        const obj = Object.entries(instance.endpointStatus)
        const len = obj.filter(([k, v]) => v === true).length

        if (len === obj.length)
            return <Tooltip title="서비스가 정상 동작 중 입니다." placement="top"><FavoriteOutlined color="info" /></Tooltip>
        else if (len === 0)
            return <Tooltip title="서비스가 동작하지 않습니다." placement="top">
                <FavoriteOutlined color="danger" />
            </Tooltip>
        else
            return <Tooltip title="일부 서비스가 동작하지 않습니다. 상세정보에서 확인하세요!!" placement="top">
                <FavoriteOutlined color="warning" />
            </Tooltip>
    } else {
        return <Tooltip title="서비스 정보가 없습니다. URL을 등록 하세요!!" placement="top">
            <FavoriteOutlined color="gray" />
        </Tooltip>
    }
}




function Row(props) {
    const user = useContext(UserContext)
    const context = useContext(MainContext)
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    function copyToClipboard(value) {
        navigator.clipboard.writeText(value)
        enqueueSnackbar("복사 되었습니다.", { variant: "info", autoHideDuration: 2000 })
    }

    function changeOpenState() {
        setOpen(!open)
        console.debug("arguments : ", arguments)
        console.debug("arguments : ", arguments.length)
    }

    return (
        <React.Fragment>
            <TableRow key={row.id + "-main"} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell width={10}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        title="상세 정보를 보여줍니다."
                        onClick={changeOpenState}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {row.group}
                    <Tooltip title="바로가기 URL을 복사 합니다." placement="top">
                        <Link href={"#"}><ContentCopy sx={{ fontSize: 16, marginX: 1 }} onClick={() =>
                            copyToClipboard(`https://systems.bwg.co.kr/group/${row.group}`)} /></Link></Tooltip>
                </TableCell>
                <TableCell>
                    {row.tags['wiki'] != null && row.tags['wiki'] !== undefined &&
                        <Tooltip title={`Gitlab에 로그인이 되어 있어야 WIKI로 이동이 가능합니다.- ${row.tags['wiki']}`} placement="top">
                            <Link target="wiki"
                                href={`https://git.bwg.co.kr/gitlab${row.tags['wiki'].startsWith("/") ? row.tags['wiki'] : '/' + row.tags['wiki']}`}>
                                WIKI
                            </Link></Tooltip>}
                </TableCell>
                <TableCell component="th" scope="row" title={row.description}>
                    {powerIcon(row, props.setAlertMsg)} {row.name}
                    <Tooltip title="바로가기 URL을 복사 합니다." placement="top">
                        <Link href={"#"}><ContentCopy sx={{ fontSize: 16, marginX: 1 }} onClick={() =>
                            copyToClipboard(`https://systems.bwg.co.kr/server/${row.name}`)} /></Link></Tooltip>
                </TableCell>
                <TableCell>
                    {row.publicIpAddress}
                    <ContentCopy sx={{ fontSize: 16, marginX: 1 }} onClick={() => copyToClipboard(row.publicIpAddress)} />
                    / {row.privateIpAddress}
                    <ContentCopy sx={{ fontSize: 16, marginX: 1 }} onClick={() => copyToClipboard(row.privateIpAddress)} />
                </TableCell>
                <TableCell>{row.tags["schedule"]}</TableCell>
                <TableCell>{row.launchTime.replace("T", " ")}</TableCell>
                <TableCell>{getEndpointStatusIcon(row)} {makeLink(row)}</TableCell>
                <TableCell>{getDiskList(row)}</TableCell>
                <TableCell>{props.idx}</TableCell>
            </TableRow>
            <TableRow key={row.id + "-detail"}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 , border: 1}}>
                            <Table aria-label="server attributes">
                                <TableBody>
                                    <TableRow key={row.id + "-detail-1"}>
                                        <TableCell className="bg-light">서버유형</TableCell>
                                        <TableCell align="left">{row.type}</TableCell>
                                        <TableCell className="bg-light">서버상태
                                            <Tooltip title="서버 정보를 조회 합니다." placement="top">
                                                <ReplayOutlined onClick={() => fetchServerInfo(row, props.setAlertMsg, context)} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">{row.state}</TableCell>
                                        <TableCell className="bg-light">
                                            {hasAuthority(row) ? <Link href={"#"} onClick={() => props.editTag(row)}>Gitlab</Link> : "Gitlab"}
                                        </TableCell>
                                        <TableCell align="left"> {getGitlabLink(row.tags["gitlab"], row.accessLevel)} </TableCell>
                                        <TableCell className="bg-light">{hasAuthority(row) ? <Link href={"#"} onClick={() => props.editTag(row)}>설명</Link> : "설명"} </TableCell>
                                        <TableCell align="left">{row.description}</TableCell>
                                    </TableRow>
                                    <TableRow key={row.id + "-detail-2"}>
                                        <TableCell className="bg-light">Architecture</TableCell>
                                        <TableCell align="left">{row.architecture}</TableCell>
                                        <TableCell className="bg-light">시작/정지 일시</TableCell>
                                        <TableCell align="left">{row.launchTime.replace("T", " ")}</TableCell>
                                        <TableCell className="bg-light">{hasAuthority(row) ? <Link href={"#"} onClick={() => props.editTag(row)}>Group</Link> : "Group"}</TableCell>
                                        <TableCell align="left">{getGroupInfo(row)}</TableCell>
                                        <TableCell className="bg-light">Public DNS</TableCell>
                                        <TableCell align="left">{row.publicDNSName}
                                            {row.publicDNSName > "" ? <ContentCopy sx={{ fontSize: 16, marginX: 1 }} onClick={() => copyToClipboard(row.publicDNSName)} /> : ""}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={row.id + "-detail-3"}>
                                        <TableCell className="bg-light">Public IP</TableCell>
                                        <TableCell align="left">{displayIpAddress(row.publicIpAddress)}</TableCell>
                                        <TableCell className="bg-light">{hasAuthority(row) ? <Link href={"#"} onClick={() => props.editTag(row)}>시작/정지 일정</Link> : "시작/정지 일정"}</TableCell>
                                        <TableCell align="left">{row.schedule}</TableCell>
                                        <TableCell className="bg-light">기본 알림 채널</TableCell>
                                        <TableCell align="left">aws-ec2-monitoring</TableCell>
                                        <TableCell className="bg-light">{hasAuthority(row) ? <Link href={"#"} onClick={() => props.editTag(row)}>Health Check</Link> : "Health Check"}</TableCell>
                                        <TableCell align="left"> {displayHealthCheck(row)} </TableCell>
                                    </TableRow>
                                    <TableRow key={row.id + "-detail-4"}>
                                        <TableCell className="bg-light">Private IP</TableCell>
                                        <TableCell align="left"> {displayIpAddress(row.privateIpAddress)} </TableCell>
                                        <TableCell className="bg-light">
                                            {hasAuthority(row) ? <Link href={"#"} onClick={() => props.editTag(row)}>백업 정책</Link> : "백업 정책"}
                                        </TableCell>
                                        <TableCell align="left">{row.tags["backup-policy"]}</TableCell>
                                        <TableCell className="bg-light">{hasAuthority(row) ? <Link href={"#"} onClick={() => props.editTag(row)}>추가 알림 채널</Link> : "추가 알림 채널"}</TableCell>
                                        <TableCell align="left">{row.mmChannel}</TableCell>
                                        <TableCell className="bg-light">{hasAuthority(row) ? <Link href={"#"} onClick={() => props.editTag(row)}>Endpoint</Link> : "Endpoint"}</TableCell>
                                        <TableCell align="left"> {displayEndpoint(row)} </TableCell>
                                    </TableRow>
                                    <TableRow key={row.id + "-detail-5"}>
                                        <TableCell className="bg-light">Subnet ID</TableCell>
                                        <TableCell align="left">{row.subnetId}</TableCell>
                                        <TableCell className="bg-light">
                                            {user.authority === 'ADMIN' ?
                                                <Link key={row.id + "-detail5-sg"} href={"#"} onClick={() => props.editSecurityGroups(row)}>방화벽</Link>
                                                : "방화벽"
                                            }
                                        </TableCell>
                                        <TableCell align="left"> {displaySecurityGroup(row)} </TableCell>
                                        <TableCell className="bg-light">{hasAuthority(row) ? <Link href={"#"} onClick={() => props.editTag(row)}>알림 추가 정보</Link> : "알림 추가 정보"}</TableCell>
                                        <TableCell align="left">{row.mmExtraInfo}</TableCell>
                                        <TableCell className="bg-light">{hasAuthority(row) ? <Link href={"#"} onClick={() => props.editTag(row)}>Wiki</Link> : "Wiki"}</TableCell>
                                        <TableCell align="left">{row.tags['wiki']}</TableCell>
                                    </TableRow>
                                    <TableRow key={row.id + "-detail-6"}>
                                        <TableCell className="bg-light">VPC ID</TableCell>
                                        <TableCell align="left">{row.vpcId}</TableCell>
                                        <TableCell className="bg-light">SSH KEY</TableCell>
                                        <TableCell align="left">{row.keyName}</TableCell>
                                        <TableCell className="bg-light">Image ID</TableCell>
                                        <TableCell align="left">{row.imageId}</TableCell>
                                        <TableCell className="bg-light">{hasAuthority(row) ? <Link href={"#"} onClick={() => props.editTag(row)}>관리자</Link> : "관리자"}</TableCell>
                                        <TableCell align="left">{row.tags['manager']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );

    function hasAuthority(instance) {
        return instance.accessLevel > 30
    }

    function getGroupInfo(row) {
        return <>
            {row.tags["group"]}
            <Tooltip title="바로가기 URL을 복사 합니다." placement="top">
                <Link href={"#"}><ContentCopy sx={{ fontSize: 16, marginX: 1 }} onClick={() =>
                    copyToClipboard(`https://systems.bwg.co.kr/group/${row.tags["group"]}`)} /></Link></Tooltip>
        </>
    }

    function displayIpAddress(ip) {
        return <>
            {ip}
            <ContentCopy sx={{ fontSize: 16, marginX: 1 }} onClick={() => copyToClipboard(ip)} />
        </>
    }

    function displayHealthCheck(row) {
        return <>
            {row.tags["health-check"] > '' && row.tags["health-check"].split(";")
                .map(e => row.profile === "default" ? e.replace("{}", row.privateIpAddress)
                    : e.replace("{}", row.publicIpAddress))
                .map((u, idx) => u.startsWith("http")
                    ? <><a href={u} target="_blank" rel="noreferrer" key={idx}>{u}</a>(
                        {row.endpointStatus && row.endpointStatus[u] ? "ok"
                            : row.endpointStatus ? "down" : "unknown"})<br /></>
                    : <>{u}<br /></>)}
        </>
    }

    function displayEndpoint(row) {
        return row.endpoint > '' && row.endpoint.split(";")
            .map(e => e.replace("{}", row.publicIpAddress))
            .map((u, idx) => u.startsWith("http") ? <><a href={u} target="_blank" rel="noreferrer" key={idx}>{u}</a><br /></>
                : <>{u}<br /></>)
    }

    function getGitlabLink(tag, accessLevel) {
        return tag ? tag.includes(",")
            ? tag.split(",")
                .map((vv, idx) => <span>{idx > 0 ? ", " : ""}{getUrl(vv, accessLevel)}</span>)
            : getUrl(tag, accessLevel)
            : tag
    }

    function displaySecurityGroup(row) {
        return row.securityGroup.map((sg, idx) =>
            <>
                {idx > 0 ? ", " : ""}
                {user.authority === 'ADMIN' || (hasAuthority(row) && sg.name.toLowerCase().startsWith(row.group.toLowerCase())) // sg.name !== "default" && sg.name !== "sg_finlab_server"
                    ? <Link href={"#"} key={idx} onClick={() => props.editSecurityGroup(row, sg.id)}>{sg.name}</Link>
                    : sg.name}
            </>)
    }

    function getUrl(vv, accessLevel) {
        return <><Tooltip key={vv} title="인프라 권한관리 Gitlab project로 이동합니다." placement="top">
            <a href={"https://git.bwg.co.kr/gitlab/projects/" + vv} target="gitlab">{vv}</a></Tooltip>
            <Tooltip title="권한 : 50 - Onwer, 40 - Maintainer, 30 - Developer" placement="top">({accessLevel})</Tooltip></>
    }

    function getDiskList(instance){
        if(instance.blockDevice.length === 0 ) return arvhiveOrRestoreIcon(instance,
            (msg) => enqueueSnackbar(msg.msg, {variant: msg.severity, autoHideDuration: 3000}))

        return instance.blockDevice.map((disk,idx) =>
            <span>
                {idx > 0 ? ", ": arvhiveOrRestoreIcon(instance,
                    (msg) => enqueueSnackbar(msg.msg, {variant: msg.severity, autoHideDuration: 3000})
                    , context)}{disk.name}({disk.size}GB)</span>)
    }
}
