import React, {createContext, useContext, useEffect, useState} from 'react';
import {callApi, UserContext} from './App';
import Login from './Login';
import Ec2 from "./server/Ec2";
import Ec2Admin from "./server/Ec2Admin";
import {SnackbarProvider} from "notistack";
import ElasticIPs from "./server/ElasticIPs";
import Snapshots from "./server/Snapshots";
import Volumes from "./server/Volumes";

export const MainContext = createContext({
    servers: [],
});


export const PATH_EC2_V2 = "/ec2-v2";
export const PATH_EC2 = "/ec2";
export const PATH_SNAPSHOT = "/snapshot";
export const PATH_VOLUME = "/volume";
export const PATH_EIP = "/eip";
const PATH_GROUP = "/group";

export default function Main() {
    const [path, setPath] = useState(window.location.pathname)

    const user = useContext(UserContext);
    const [servers, setServers] = useState([])
    const [filter, setFilter] = useState([])
    const [keyword, setKeyword] = useState("")

    useEffect(() => {
        if (user.email === undefined || user.email === '') return
        if (servers.length === 0) callApi('/ec2/instances', (result) => {
            if (result.length > 0) setServers(result)
            const groupList = result.reduce((acc, curr) => {
                const groups = curr.group.split(',').map(v => v.trim());
                acc.push(...groups);
                return acc;
            }, []);

            if (result.length > 0) setFilter([...new Set(groupList)])

        }, null, (msg) => { alert(msg.msg)})
    }, [user.email, servers])

    const context = { path: path, setPath: setPath, filter: filter, servers: servers, setServers: setServers, keyword: keyword, setKeyword: setKeyword };


    console.debug("pathname : ", path, user.email)

    //if (path === "/" && user.email && user.isAdmin) setPath(PATH_EC2_V2)

    return (<MainContext.Provider value={context}>
        <SnackbarProvider maxSnack={5} autoHideDuration={10000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            {/* {user.email && user.isAdmin && <Ec2Admin/>} */}
            {/* {user.email && !user.isAdmin && <Ec2/>} */}
            {user.email && path === PATH_EC2_V2 && <Ec2Admin />}
            {user.email && path === PATH_EIP && <ElasticIPs />}
            {user.email && path === PATH_SNAPSHOT && <Snapshots />}
            {user.email && path === PATH_VOLUME && <Volumes />}
            {user.email && (path === PATH_EC2 || path === "/") && <Ec2 />}

            {user.email === "" && <Login />}
        </SnackbarProvider>
    </MainContext.Provider>);
}