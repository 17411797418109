import React, {useContext, useEffect, useState} from "react";
import ResourceToolbar from "./ResourceToolbar";
import {MainContext, PATH_SNAPSHOT} from "../Main";
import {callApi, deleteApi, saveApi} from "../App";
import {
    Button,
    Container, Input,
    Link,
    Menu,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useSnackbar} from "notistack";
import {DeleteOutline, ModeEdit} from "@mui/icons-material";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";

export default function Snapshots() {
    const { enqueueSnackbar } = useSnackbar();
    const context = useContext(MainContext)
    const [resourceName,setResourceName] = useState("")
    const [dataMap, setDataMap] = useState({});
    const [listData, setListData] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const handleRowClick = (key) => {
        setSelectedGroup(key);
    };

    useEffect(() => {
        if(context.path !== PATH_SNAPSHOT) return
        reload()
    },[context.path]);

    const sortedValues = selectedGroup ? [...dataMap[selectedGroup]].sort((a, b) => new Date(b.startTime) - new Date(a.startTime)) : [];

    return (<>
        <ResourceToolbar reload={reload} resourceName={resourceName} searchResource={setResourceName}/>
        <div className="row px-3 py-1"></div>
        <div className="row px-4 py-5">
            <Box display="flex" height="90vh">
                <TableContainer component={Paper} style={{ width: '35%', marginRight: '10px', overflowY: 'auto' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className="bg-light">서버</TableCell>
                                <TableCell className="bg-info">Volume ID({listData.length})</TableCell>
                                <TableCell className="bg-light">스냅샷 수({listData.reduce((sum, item) => sum + item.cnt, 0)})</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listData
                                .filter((item) => dataMap[item.volumeId].filter(s => filterSnapshot(item, s)).length > 0)
                                .map((item) => (
                                <TableRow
                                    key={item.volumeId}
                                    onClick={() => handleRowClick(item.volumeId)}
                                    style={{ cursor: 'pointer', backgroundColor: selectedGroup === item.volumeId ? '#f5f5f5' : 'inherit' }}
                                >
                                    <TableCell>{item.server}</TableCell>
                                    <TableCell>{item.volumeId}</TableCell>
                                    <TableCell>{item.cnt}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} style={{ width: '65%' }}>
                    {selectedGroup ? (
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="bg-light">#</TableCell>
                                    <TableCell className="bg-light">Name</TableCell>
                                    <TableCell className="bg-light">ID</TableCell>
                                    <TableCell className="bg-light">Start Time</TableCell>
                                    <TableCell className="bg-light">Size (GB)</TableCell>
                                    <TableCell className="bg-light">Description</TableCell>
                                    <TableCell className="bg-light">Tag</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedValues.map((item, index) => (
                                    <TableRow key={item.id} className={item.description.includes("ami-") ? "bg-info" : ""}>
                                        <TableCell>{index+1}</TableCell>
                                        <TableCell>{deleteIcon(item)} {item.tags["Name"]} <TagEditor tagKey="Name" snapshot={item} reload={reload}/> </TableCell>
                                        <TableCell>{item.id}</TableCell>
                                        <TableCell>{new Date(item.startTime).toLocaleString()}</TableCell>
                                        <TableCell>{item.volumeSize}</TableCell>
                                        <TableCell>{item.description}</TableCell>
                                        <TableCell>{item.description.includes("backup")
                                            ? Object.entries(item.tags).filter(([k]) => k === "instance-id").map(([k,v])=> (<>{`${k} : ${v}`}<br/></>))
                                            : Object.entries(item.tags).map(([k,v])=> (<>{`${k} : ${v}`}<br/></>))}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <Typography variant="h6" style={{ margin: '20px' }}>Select a group to see details</Typography>
                    )}
                </TableContainer>
            </Box>
        </div>
    </>)

    function reload() {
        const uri = "/snapshots/list?profile=default"
        callApi(uri, (data) => {
            const listData = Object.entries(data).map(([key, value]) => {
                return {volumeId : key, server : getServerName(key), cnt : value.length }
            }).sort((a, b) => {
                if (!a.server) return 1;
                if (!b.server) return -1;
                return a.server.localeCompare(b.server)
            })
            setListData(listData);
            setDataMap(data)
            //enqueueSnackbar("조회 되었습니다", {variant: "success", autoHideDuration: 2000})
        }, {}, () => {})
    }

    function getServerName(key) {
        const list = context.servers
            .filter((ec2) => ec2.blockDevice.length > 0)
            .filter((ec2) => {
                return ec2.blockDevice.filter(d => d.volumeId === key).length > 0;
            })
            .map(ec2 => `${ec2.group} > ${ec2.name}(${ec2.state})`)

        if (list.length > 0) return list[0]
        return ""
    }

    function deleteIcon(item) {
        if( (item.tags["Name"]&& item.tags["Name"].includes(": Archive") ) || item.description.includes("ami-")) return ""

        return <Tooltip title="Snapshot을 삭제 합니다" placement="top">
                <Link onClick={() => deleteSnapshot(item)}><DeleteOutline /></Link>
        </Tooltip>
    }

    function deleteSnapshot(item) {
        const name = item.tags["Name"] ? item.tags["Name"] : ""
        if(!window.confirm(`${name} - ${item.id} Snapshot을 삭제하시겠습니까?\n삭제하면 복구 할 수 없습니다!!!`)) return

        const uri = `/snapshots/${item.id}/delete?profile=default`

        deleteApi(uri, {profile: "default", volumeId: item.volumeId}, (msg) => {
            enqueueSnackbar(`삭제 되었습니다 - ${msg.msg}`, {variant: "success", autoHideDuration: 2000})
            reload()
        }, null, (msg) => {
            enqueueSnackbar(`삭제 하지 못했습니다 - ${msg.msg}`, {variant: "error", autoHideDuration: 2000})
            reload()
        })
    }

    function filterSnapshot(item, snapshot){
        return resourceName === ""
            || (snapshot.tags["Name"] && snapshot.tags["Name"].includes(resourceName))
            || item.volumeId.includes( resourceName )
            || item.server.includes(resourceName)
            || snapshot.id.includes(resourceName)
            || snapshot.description.includes(resourceName)
    }
}

/**
 * 해당 서버의 태그를 수정 편집하는 popup editor, 편집 tag에 바로 위치 시킨다.
 *
 * @param {String} tagKey - 태그 키 이름
 * @param {Object} snapshot - 서버 정보 객체
 * @param {function} reload - Snapshot 목록 재 조회 함수
 * @returns
 */
function TagEditor({ tagKey, snapshot, reload }) {
    const context = useContext(MainContext)
    const [value, setValue] = useState(snapshot.tags[tagKey])
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        //console.debug("[TagEditor.useEffect] ", value, snapshot.tags[tagKey])
        if (value !== snapshot.tags[tagKey]) setValue(snapshot.tags[tagKey])
    }, [tagKey])

    const width = "300px"
    return (
        <PopupState variant="popover" popupId="family-site-popup-menu" disableAutoFocus={true} parentPopupState={null}>
            {(popupState) => (
                <React.Fragment>
                    <Tooltip title="Snapshot 이름" placement="top">
                        <Link variant="contained" {...bindTrigger(popupState)}>
                            <ModeEdit sx={{ fontSize: 16, marginX: 1 }} />
                        </Link>
                    </Tooltip>
                    <Menu {...bindMenu(popupState)}>
                        <Container sx={{ width: width }} className="row">
                            <Input aria-label={tagKey} multiline value={value} sx={{ width: '100%' }}
                                   onChange={(e) => setValue(e.target.value)} placeholder="Snapshot의 이름을 입력하세요." />
                            <Button onClick={saveTag} disabled={value === snapshot.tags[tagKey]}>저장</Button>
                        </Container>
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );

    /**
     * 저장
     */
    function saveTag() {
        if (value === snapshot.tags[tagKey]) return

        saveApi(`/snapshots/${snapshot.id}/tag/${tagKey}/change`, "PUT",
            { value: value, profile: "default", volumeId: snapshot.volumeId }, (result) => {
                console.debug("[Snapshot.saveTag] tag change : ", result)
                enqueueSnackbar("저장 되었습니다. ", { variant: "success" })
                reload()
            }, (msg) => { enqueueSnackbar(msg.msg, { variant: "error" }) }
        )
    }
}
