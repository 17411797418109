import React, {useContext, useState} from "react";
import {MainContext} from "../Main";
import ResourceToolbar from "./ResourceToolbar";
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

export default function Volumes() {

    const context = useContext(MainContext)
    const [resourceName,setResourceName] = useState("")

    return (<>
        <ResourceToolbar reload={reload} resourceName={resourceName} searchResource={setResourceName} />
        <div className="row px-3 py-1"></div>
        <div className="row px-4 py-5">
            <Table sx={{overflowX: 'auto'}} size="small" aria-label="server list table" component={Paper}>
                <TableHead >
                    <TableRow>
                        <TableCell>Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Name</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    </>)

    function reload() {

    }
}